@media print {
  .alert-bar, .main-header, .social-nav {
    display:none !important;
  }
  .top{
    position: static;
  }


  a {
    color: #000 !important;
  }
  p a[href^="#"]:after {
    display: none;
  }



}
