@import 'header/alert-bar.scss';

// Header
.top {
	float: left;
	width: 100%;
	position: fixed;
  top: 0;
	z-index: 9998;
	opacity: 1;
	//@include transition(all 0.3s); //no unecessary transation
}

.admin-bar {
	// .top {
	// 	top: 46px;
	// 	@include breakpoint($bp-charlie) {
	// 		top: 32px;
	// 	}
	// }
	// &.sticky {
	// 	.top {
	// 		@include breakpoint($bp-charlie) {
	// 			top: 32px;
	// 		}
	// 	}
	// }
}

.pusher {
	height: 0px;
	@include transition(all 0.3s);
}

body.search-open {
    .pusher {
        margin-top: 0px;
    }
    .top {
        top: 61px;
    }
    .top-search {
        top: 0;
        opacity: 1;
    }
}

.top-search {
	height: 61px;
	background: #eee;
	padding: 0px 0;
	position: fixed;
	top: 0;
	width: 100%;
	top: -61px;
	@include transition(all 0.3s);
	z-index: 9999;
	opacity: 0;
	.container {
		padding: 0;
	}
	form {
		position: relative;
		height: 61px;
		display: block;
		float: left;
		width: 100%;
	}
	.container {
		width: 100%;
		max-width: 1195px;
	}
	.arrow {
		position: absolute;
		right: 23px;
		top: 50%;
		@include transform(translateY(-50%));
		svg {
			width: 22px;
			color: $body;
			@include hover-fade();
			cursor: pointer;
			&:hover {
				color: $secondary;
			}
		}
	}
	input[type="text"] {
		width: 100%;
		height: 61px;
		background: #eee;
		color: $body;
		font-family: $headline-font;
		font-weight: 400;
		font-size: 29px;
		line-height: 61px;
		letter-spacing: 0;
		opacity: 1;
		padding: 0px 60px 00px 10px;
		border: none!important;
		@include transition(all 0.3s);
		box-shadow: none!important;
		position: relative;
		top: 47%;
		@include transform(translateY(-50%));
		&::-webkit-input-placeholder {
			top: 0%;
			position: relative;
		  	color: $body;
		}
		&::-moz-placeholder {
			top: 0%;
			position: relative;
		  	color: $body;
		}
		&:-ms-input-placeholder {
			top: 0%;
			position: relative;
		  	color: $body;
		}
		&:-moz-placeholder { 
			top: 0%;
			position: relative;
		  	color: $body;
		}
	}
	input[type="submit"] {
		display: none;
	}
}

.main-header {
	float: left;
	width: 100%;
	padding: 4px 0;
	height: $header-mobile-height;
	background-color: $navBackground;
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), transparent);
	background-size: 100% 0;
	padding-top: 20px;
	@include transition(all 0.3s ease-in-out);
	position: relative;
	@include breakpoint($bp-charlie) {
		height: $header-height;
		padding: 14px 0;
		padding-top: 20px;
	}
	@include breakpoint($bp-bobby) {
		padding-top: 50px;
	}
	.sticky & {
		background-size: 100% 100%;
		@include breakpoint($bp-bobby) {
			padding-top: 14px;
		}
	}
	.container-navigation {
		padding: 0 20px;
		width: 100%;
		max-width: 1210px;
		margin: 0 auto;
	}
	.search-icon {
		float: right;
		padding: 9px 10px;
		margin-left: 20px;
		margin-right: 50px;
		cursor: pointer;
		@include hover-fade();
		@include breakpoint($bp-bobby) {
			margin-left: 20px;
			margin-right: 0;
		}
		&:hover {
			i {
				color: $secondary;
			}
		}
		i {
			@include hover-fade();
			color: #fff;
			font-size: 23px;
			@include breakpoint($bp-charlie) {
				font-size: 23px;
			}
		}
	}
	.logo {
		position: relative;
		z-index: 9999;
		img, svg {
			float: left;
			width: 220px;
			height: 40px;
			margin: 3px 0 0 0;
			@include hover-fade();
			@include breakpoint($bp-bravo) {
				width: 248px;
				height: 45px;
			}
			@include breakpoint($bp-bobby) {
				width: 274px;
				height: 50px;
			}
		}
		svg {
			.st0, .st1 {
				fill: #fff;
			}
		}
	}
	.site-tagline {
		display: none;
		@include breakpoint($bp-bobby) {
			font-family: $primary-font;
			font-size: 14px;
			line-height: 1.21;
			color: rgba(255, 255, 255, 0.6);
			width: 131px;
			display: inline-block;
			margin-left: 25px;
			position: absolute;
			top: 50%;
			@include transform(translateY(-50%));
		}
	}
	.control {
		float: right;
	}
	.social {
		&.links {
			display: none;
			@include breakpoint($bp-echo) {
				display: block;
				float: right;
				margin: 8px 0 0 0;
				padding-left: 11px;
				@include hover-fade();
				color: #fff;
				border-left: 1px solid $details;
			}
			ul li {
				i {
					font-size: 17px;
				}
				a {
					color: $socialColor;
					padding: 0 11px;
					&:hover {
						color: $socialHover;
					}
				}
			}
		}
	}
	nav {
		float: right;
		&.primary {
			a {
				font-weight: 400;
				color: $navColor;
				&:hover {
					color: $navColorHover;
				}
			}
		}
	}
}

// Navigation
nav {
	&.primary {
		display: none;

		@include breakpoint($bp-echo) {
			margin-right: 11px;
		}

		@include breakpoint($bp-bobby) {
			display: block;
		}

		@include breakpoint($bp-charlie) {
			float: right;
			margin: -4px 0 0 0;
			@include hover-fade();
			ul {
				margin: 0 0 0 0;
				display: table;
				li {
					margin: 0;
					padding: 13px 8px;
					position: relative;
					font-family: $headline-font;
					font-size: 16px;
					font-weight: 600;
					&:last-of-type {
						padding-right: 0;
						@include breakpoint($bp-echo) {
							padding-right: 20px;
						}
					}
					@include breakpoint($bp-echo) {
						padding: 13px 13px;
					}
					&.home {
						display: none;
					}
					&.menu-item-has-children {
						&:hover {
							.sub-menu {
								display: block;
								visibility: visible;
								margin-top: 0;
								opacity: 1;
								li {
									-webkit-animation: fadeIn 0.5s ease-in-out; 
									-webkit-animation-fill-mode: both;
									animation: fadeIn 0.5s ease-in-out; 
									animation-fill-mode: both;
								}
							}
						}
					}
					&:first-child {
						border-left: 0px;
					}
					&:last-child {
						margin-right: 0px;
					}
					.sub-menu {
						width: 195px;
						position: absolute;
						top: 65px;
						left: 58%;
						-webkit-transform: translateX(-50%);
						-ms-transform: translateX(-50%);
						transform: translateX(-50%);
						background: rgba($dropDownBg, 1);
						padding: 25px;
						visibility: hidden;
						opacity: 0;
						margin-top: 20px;
						@include hover-fade();
						@include box-shadow($boxShadowTop, $boxShadowLeft, $boxShadowBlur, $boxShadowColor);
						&:before {
							content: '';
							width: 0; 
							height: 0; 
							border-left: 11px solid transparent;
							border-right: 11px solid transparent;
							border-bottom: 12px solid rgba($dropDownBg, 1);
							position: absolute;
							top: -12px;
							left: 0;
							right: 0;
							margin: 0 auto;
						}
						&:after {
							content: '';
							height: 22px;
							width: 100%;
							position: absolute;
							top: -22px;
							left: 0;
							background:transparent;
						}
						li {
							width: 100%;
							margin: 0 0 14px 0;
							padding: 0;
							border: 0!important;
							line-height: 120%;
							font-family: $headline-font;
							font-size: 17px;
							&:last-child {
								margin-bottom: 0!important;
							}
							a {
								font-family: $primary-font;
								font-weight: 700;
								color: $dropDownColor;
								margin: 0;
								&:hover {
									color: $dropDownHover;
									opacity: 1!important;
								}
							}
						}
					}
				}
			}
		}
		
	}
	&.secondary {
		display: none;
		ul {
			li {
				a {
					display: block;
					float: left;
					text-align: center;
					background: $primary;
					color: #fff;
					height: 43px;
					border-radius: 3px;
					line-height: 41px;
					width: 155px;
					font-size: 16px;
					font-weight: 600;
					font-family: $headline-font;
					@include box-shadow($boxShadowTop, $boxShadowLeft, $boxShadowBlur, $boxShadowColor);
					&:hover {
						background: #fff;
						color: $secondary;
					}
					@include breakpoint($bp-echo) {
						width: 175px;
					}
				}
				&:last-child {
					a {
						background: $secondary;
						&:hover {
							background: #fff;
							color: $secondary;
						}
					}
				}
			}
		}
		@include breakpoint($bp-bobby) {
			display: block;
			float: right;
			@include hover-fade();
			padding-top: 2px;
			ul {
				li {
					&:last-child {
						margin: 0 0 0 10px;
					}
				}
			}
		}
	}
	@include breakpoint($bp-charlie) {
		ul {
			li {
				float: left;
				list-style-type: none;
			}
		}
	}
}

.page-no-topper {
	.hamburger-inner {
		&, &:before, &:after {
			background-color: $secondary;
		}
	}

	.main-header {
		.logo {
			svg {
				.st0, .st1 {
					@include hover-fade();
				}

				.st0 {
					fill: #202A49;
				}

				.st1 {
					fill: #95A2AF;
				}
			}
		}

		.site-tagline {
			color: $details;
			@include hover-fade();
		}

		nav.primary {
			a {
				color: $secondary;

				&:hover {
					color: rgba($secondary, 0.5);
				}
			}
		}

		.social.links {
			ul {
				li {
					a {
						color: $secondary;
					}
				}
			}
		}
	}

	&.sticky {
		.hamburger-inner {
			&, &:before, &:after {
				background-color: #fff;
			}
		}

		.main-header {
			.logo {
				svg {
					.st0, .st1 {
						fill: #fff;
					}
				}
			}

			.site-tagline {
				color: rgba(#fff, 0.5);
			}

			nav.primary {
				a {
					color: #fff;

					&:hover {
						color: rgba(#fff, 0.5);
					}
				}
			}

			.social.links {
				ul {
					li {
						a {
							color: #fff;
						}
					}
				}
			}
		}
	}
}

// Mobile Nav Icon
.hamburger {
	float: right;
	margin: 34px 0 0 0;
	z-index: 11;
	position: absolute;
	top: 0;
	right: 20px;
	z-index: 9998;
	@include hover-fade();
	@include breakpoint($bp-bobby) {
		display: none;
		margin: 26px 0 0 0;
	}
	.hamburger-inner {
		&, &:before, &:after {
			@include hover-fade();
		}
	}
	&:hover {
		.hamburger-inner {
			background-color: $primary;
			width: 33px;
			&:after, &:before {
				background-color: $primary;
				width: 33px;
			}
		}
	}
	&.is-active {
		.hamburger-inner {
			&, &:after {
				background-color: white;
				width: 33px;
			}
		}
	}
}

// Mobile Nav
.mobile-nav {
	position: fixed;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	background: rgba($body,1);
	z-index: 10;
	height: 100%;
	display: none;
	nav {
		float: left;
		width: 100%;
		&.mobile {
			@include vertical-align();
			ul {
				margin: 0px;
				padding: 0px;
				float: left;
				width: 100%;
				li {
					float: left;
					width: 100%;
					text-transform: uppercase;
					text-align: center;
					font-size: 30px;
					margin: 0 0 10px;
				}
			}
		}
	}
}


/* Menu */
.menu-wrap {
	position: fixed;
	height: 100%;
	width: 100%;
	z-index: -1;
	top: 0;
	right: 0;
	background: transparent;
	font-weight: 600;
	opacity: 0;
	@include hover-fade();
	-webkit-transition-timing-function: cubic-bezier(0.5,0,0.4,0.5);
	transition-timing-function: cubic-bezier(0.5,0,0.4,0.5);
	left: 100%;
	.align-middle {
		padding: 0 30px;
		width: 100%;
		position: absolute;
		top: 50%;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	.menu {
		float: left;
		width: 100%;
		overflow: hidden;
		list-style: none;
		li {
			width: 100%;
			float: left;
			text-align: center;
			font-size: 25px;
			font-weight: 600;
			font-family: $headline-font;
			margin-bottom: 0px;
			a {
				color: #fff;
				padding: 5px 0px;
				&:hover {
					color: $secondary;
				}
			}
		}
	}
	.secondary {
		margin: 20px 0 15px 0;
		float: left;
		width: 100%;
		overflow: hidden;
		list-style: none;
		display: block;
		li {
			display: table;
			margin: 0 auto 10px auto!important;
			text-align: center;
			a {
				float: none;
				display: table;
				margin: 0 auto;
				width: 100%;
				height: 52px;
				max-width: 325px;
				font-size: 18px;
			}
		}
	}
	.social-mobile {
		float: left; 
		width: auto;
		width: 100%;
		.social {
			display: table;
			margin: 0 auto;
			float: none;
			width: auto;
			ul {
				li {
					a {
						color: #999999;
						&:hover {
							color: $secondary
						}
						i {
							font-size: 32px;
						}
					}
				}
			}
		}
	}
}

.menu-top {
	line-height: 58px;
}

.menu-top .profile {
	display: inline-block;
	padding: 8px 10px;
	line-height: 42px;
}

.menu-top .profile,
.menu-side {
	width: 300px;
}

.menu-top .profile img {
	float: left;
	margin-right: 1em;
}

.icon-list { 
	display: inline-block;
	font-size: 1.25em;
}

.icon-list a {
	margin: 0 1em 0 0;
	padding: 0;
}

@media screen and (max-width: 32em) {
	.icon-list {
		padding-left: 1em;
	}
}

.menu-side a {
	display: block;
	padding: 1.2em;
	border-bottom: 1px solid rgba(0,0,0,0.1);
}

.menu-side a:first-child {
	border-top: 1px solid rgba(0,0,0,0.1);
}



#container-wrap {
	float: left;
	width: 100%;
	opacity: 1;
	left: 0;
	@include hover-fade();
}

/* Shown menu */
.show-menu {
	.top {
		opacity: 0;
	}
	#container-wrap {
		left: -100%;
		//opacity: 0;
		-webkit-transform: translate3d(-100%,0,0);
		transform: translate3d(-100%,0,0);
		@include hover-fade();
		-webkit-transition-timing-function: cubic-bezier(0.2,0,0.3,0.4);
		transition-timing-function: cubic-bezier(0.2,0,0.3,0.4);
	}
	.menu-wrap {
		opacity: 1;
		-webkit-transform: translate3d(0,0,0);
		transform: translate3d(0,0,0);
		@include hover-fade();
		-webkit-transition-timing-function: cubic-bezier(0.5,0,0.4,0.5);
		transition-timing-function: cubic-bezier(0.5,0,0.4,0.5);
		left: 0;
	}
}
