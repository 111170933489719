.top-introduction {
	float: left;
	width: 100%;
	background: #fff;
	margin-top: 35px;
	@include breakpoint($bp-charlie) {
		margin-top: 55px;
	}
	.col-md-8, .col-md-9 {
		float: none;
		display: table;
		margin: 0 auto;
	}
}


.issues-page {
	float: left;
	width: 100%;
	font-size: 0px;
	.container {
		max-width: 1230px;
		width: 100%;
	}
	&.inpage {
		h3, p {
			text-align: center;
		}
		p {
			margin-bottom: 0px;
		}
	}
	&.person {
		.issues-grid {
			padding: 0px;
		}
		.grid-image-container.grid-image-container--people {
			background-color: $details;

			.grid-image {
				opacity: 1;
			}
		}
		.single-issue {
			height: 440px;

			@include breakpoint($bp-bobby) {
				margin-bottom: 0;
			}

			&:before {
				display: none;
			}

			& > a {
				&:before {
					content: '';
					background-image: linear-gradient(to top, #0a1022, transparent 50%);
					border: 2px solid #fff;
					display: block;
					position: absolute;
					top: -1px;
					left: -1px;
					right: -1px;
					bottom: -1px;
					@include hover-fade();
				}

				&:after {
					content: '';
					background-image: linear-gradient(0deg, $primary, transparent 50%);
					border: 2px solid #fff;
					display: block;
					position: absolute;
					top: -1px;
					left: -1px;
					right: -1px;
					bottom: -1px;
					opacity: 0;
					@include hover-fade();
				}
			}

			.wrapper {
				top: auto;
				bottom: 40px;
				@include transform(none);
			}

			&:hover {
				z-index: 2;

				& > a {
					&:before {
						opacity: 0;
					}

					&:after {
						opacity: 1;
					}
				}

				.grid-image-container .grid-image {
					opacity: 1;
				}
			}
			&:before {
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
			}
			.wrapper {
				z-index: 100;
			}
			h6 {
				font-size: 28px;
				color: #fff;
			}
			small {
				text-transform: none;
				font-family: $primary-font;
				font-weight: 400;
				font-size: 16px;
				color: #fff;
			}
			.wrapper {
				.social.links {
					text-align: center;
					width: 100%;
					position: static;
					height: 0;
					margin: 0;

					ul li {
						float: none;
						font-size: 24px;

						a {
							z-index: 100;

							&:hover {
								color: rgba(#fff, 0.5);
							}
						}

						.fa-facebook {
							font-size: 23px;
						}
					}
				}
			}
		}
		&:hover {
			.single-issue {
				.wrapper {
					.social.links {
						@include transform(translateY(20px));
					}
				}
			}
		}
	}
}

.issues-grid {
	width: 100%;
	display: table;
	font-size: 0px; /*Important for Firefox*/
	&.has-intro {
		padding-top: 25px;
		@include breakpoint($bp-charlie) {
			padding-top: 40px;
		}
		@include breakpoint($bp-bobby) {
			padding-top: 60px;
		}
	}
	&.has-buttons {
		padding-bottom: 20px;
		@include breakpoint($bp-charlie) {
			padding-bottom: 35px;
		}
		@include breakpoint($bp-bobby) {
			padding-bottom: 55px;
		}
	}
	@include breakpoint($bp-bobby) {
		text-align: center;
	}

	&.four {
		.single-issue {
			@include breakpoint($bp-bobby) {
				width: calc(25% - 3%);
			}
		}
	}

	.single-issue {
		float: none;
		width: 100%;
		max-width: 385px;
		margin: 6px auto;
		border-radius: $borderRadius;
		border: 6px solid #565e65;
		height: 275px;
		position: relative;
		@include hover-fade();
		background-image: linear-gradient(to bottom, transparent, transparent);

		@include breakpoint($bp-charlie) {
			width: calc(50% - 3%);
			height: 325px;
			float: none;
			display: inline-block;
			margin: 1.5%;
		}
		@include breakpoint($bp-bobby) {
			width: calc(33.33% - 3%);
		}

		//when visible, assume that items will be hovered and change
		//something related to this causes the sticky nav to jump around, which is crazy, not sure what the fix is
		.activeSection & {
			will-change: transform, background-color;
			p, small {
				&.teaser{
					will-change: transform, max-height, opacity;
				}
			}
		}

		&:before {
			content: '';
			display: block;
			background-image: linear-gradient(to bottom, $primary, $tertiary);
			position: absolute;
			top: -6px;
			left: -6px;
			right: -6px;
			bottom: -6px;
			opacity: 0;
			@include hover-fade();
		}

		&:hover {
			background-color: $primary;
			border-color: transparent;
			z-index: 2;
			&:before {
				opacity: 1;
			}
			@include transform(scale(1.1, 1.1));
			.grid-image-container {
				background: $primary;
				.grid-image {
					opacity: .1;
				}
			}
			.read-more {
				opacity: 1;
				@include transform(translate(-50%, 0));
			}
			.wrapper {
				h5, h6, p, small {
					color: #fff;
				}
				p, small {
					&.teaser {
						opacity: 1;
						position: relative;
						max-height: 275px;
						@include hover-fade();
						@include transform(translateY(0%));

						@include breakpoint($bp-charlie) {
							max-height: 325px;
						}

						@include breakpoint($bp-bobby) {
							max-height: 350px;
						}
					}
				}
				.social.links {
					@include transform(translateY(0%));
					opacity: 1;
				}
			}
		}
		a {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 99;
		}
		&.has-image {
			border-width: 0;
			&:before {
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
			}
			.wrapper {
				h5, p, small {
					color: white;
				}
			}
		}

		.text-container {
			padding-left: 15px;
			padding-right: 15px;
		}

		.read-more {
			text-transform: uppercase;
			font-weight: 700;
			font-size: 16px;
			letter-spacing: 0.1em;
			color: #fff;
			width: 100%;
			position: absolute;
			left: 50%;
			bottom: 30px;
			opacity: 0;
			@include hover-fade();
			@include transform(translate(-50%, 10px));

			svg {
				width: 20px;
				height: 13px;
				margin-left: 10px;
				@include transform(translateY(-2px));

				.cls-1 {
					stroke: #fff;
				}
			}
		}

		.grid-image-container {
			height: 100%;
			background: $secondary;
			.grid-image {
				background-size: cover !important;
				background-position: 50% 50% !important;
				height: 100%;
				width: 100%;
				opacity: .4;
				@include hover-fade();
			}
		}
		.wrapper {
			position: absolute;
			top: 50%;
			@include transform(translateY(-50%)); 
			z-index: 98;
			left: 0;
			right: 0;
			h5, h6 {
				text-align: center;
				margin: 0px;
				@include hover-fade();
			}
			h5 {
				font-family: $headline-font;
				font-size: 30px;
				padding: 0px 60px;
			}
			h5, p.teaser {
				color: #565e65;
			}
			p.teaser {
				font-size: 16px;
				color: #fff;
			}
			small {
				display: block;
			}
			p, small {
				text-align: center;
				margin: 0;
				@include transition(0.2s);
				&.teaser {
					position: absolute;
					@include transform(translateY(20%));
					@include transition(0.15s);
					width: 100%;
					left: 0;
					right: 0;
					padding: 0px 30px;
					opacity: 0;
					line-height: 130%;
					max-height: 0;
					overflow: hidden;
				}
			}
			.social.links {
				width: auto;
				position: absolute;
				@include transform(translateY(50%));
				opacity: 0;
				@include hover-fade();
				bottom: 35px;
				z-index: 99;
				ul {
					position: relative;
					width: 100%;
					top: 8px;
					li {
						display: inline-block;
						float: left;
						font-size: 18px;
						.fa-facebook {
							font-size: 17px;
						}
						a {
							position: relative;
							padding: 2px 7px 0px;
							color: white;
							&:hover {
								color: $body;
							}
						}
						&:first-child {
							a {
								padding-left: 0px;
							}
						}
					}
				}
			}
		}
	}
}
