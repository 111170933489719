/* Variables are only for the topper menus */

$solidBgColor	: $body;
$headlineColor	: #fff;
$paragraphColor	: #fff;

//Tall Topper Height
$tallDesktop	: 725px;
$tallTablet		: 540px;

//Short Topper Height
$shortDesktop	: 420px;
$shortTablet	: 420px;

.home .topper {
	height: 100vh !important;
	max-height: 650px;
	min-height: 450px;
	padding: 0 0 0 0;
	@include transition(all 0.3s ease-in-out);
	//margin-top: $header-mobile-height;
	@include breakpoint($bp-charlie) {
		max-height: 650px;
		min-height: 550px;
		//margin-top: $header-height;
	}
	@include breakpoint($bp-bobby) {
		max-height: 850px;
		min-height: 720px;
		//margin-top: $header-height;
	}
	.container {
		position: relative;
		z-index: 2;
		padding-top: 0 !important;
	}
	.row {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.vertical-centered {
		top: 10px !important;
		@include transform(none !important);

		@include breakpoint($bp-bobby) {
			top: 50px !important;
		}
	}
}

.topper {
	float: left;
	width: 100%;
	background-size: cover !important;
	position: relative;
	padding: 40px 0 40px;
	background-color: $secondary;
	//margin-top: $header-mobile-height;
	@include breakpoint($bp-charlie) {
		padding: 0 0 0 0;
		//margin-top: $header-height;
	}
	h1, h2, h3, h4, h5, h6 {
		color: #fff;
	}
	h6 {
		text-transform: uppercase;
		font-family: $primary-font;
		font-size: 16px;
		letter-spacing: 0.1em;;
		margin-bottom: 10px;
	}
	h1 {
		margin-bottom: 25px;
	}
	p {
		color: $paragraphColor;
	}
	.container {
		height: 100%;
		max-width: 1000px;
		box-sizing: border-box;
		padding-top: $header-height * 0.9;
		.js & {
			opacity: 0;
		}
		.row {
			height: 100%;
		}
	}
	.donation {
        margin: 15px 0 0 0;
        @include breakpoint($bp-charlie) {
            margin: 20px 0 0 0;
        }
        .donation-note {
            color: white;
        }
        .col-lg-8 {
            margin: 0 auto;
            float: none;
        }
        ul {
            li {
                font-weight: 700;
            }
        }
    }
	.deck {
		font-size: 20px;
		line-height: 1.41;
		color: #fff;
		max-width: 728px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 25px;
		@include breakpoint($bp-charlie) {
			font-size: 27px;
			margin-bottom: 45px;
		}
		p {
			font-size: 16px;
			margin-bottom: 20px;
			&:last-of-type {
				margin-bottom: 0;
			}

			@include breakpoint($bp-bravo) {
				font-size: 20px;
			}

			@include breakpoint($bp-charlie) {
				font-size: 26px;
			}

			span {
				font-size: 18px;

				@include breakpoint($bp-bobby) {
					font-size: 20px;
				}
			}
		}
	}
	&.short {
		height: auto;
		padding: 65px 0 55px 0;
		h1 {
			font-size: 36px;
			@include breakpoint($bp-bobby) {
				font-size: 50px;
			}
		}
		.deck {
			margin-bottom: 0px;
		}
		@include breakpoint($bp-charlie) {
			height: $shortTablet;
		}
		@include breakpoint($bp-bobby) {
			height: $shortDesktop;
		}
	}
	&.tall {
		height: auto;
		padding: 85px 0 100px 0;
		@include breakpoint($bp-charlie) {
			height: $tallTablet;
		}
		@include breakpoint($bp-bobby) {
			height: $tallDesktop;
			min-height: 600px;
		}
		.deck {
			display: block;
		}
	}
	&.home {
		height: auto;
		@include breakpoint($bp-charlie) {
			height: $tallTablet;
			padding: 0 0 0 0;
		}
		@include breakpoint($bp-bobby) {
			height: $tallDesktop;
			padding: 0 0 0 0;
		}
		.deck {
			display: block;
			font-size: 16px;
			@include breakpoint($bp-bravo) {
				font-size: 20px;
			}
			@include breakpoint($bp-charlie) {
				display: block;
			}
			@include breakpoint($bp-bobby) {
				font-size: 27px;
			}
		}
	}
	.read-more {
		position: absolute;
		z-index: 2;
		bottom: 20px;
		left: 0;
		right: 0;
		margin: 0 auto;
		max-width: 57px;
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			left: 50%;
			@include transform(translateX(-50%));
		}
		@include breakpoint($bp-bobby) {
			max-width: 80px;
			bottom: 35px;
		}
		a {
			padding: 10px;
			float: left;
			width: 100%;
			height: auto;
			color: #fff;
			.st1:first-of-type {
				display: none;
			}
			.st1 {
				fill: $primary;
			}
			svg {
				position: relative;
				width: 100%;
				@include hover-fade();
			}
			&:hover {
				color: $secondary;
				svg {
					@include transform(scale(1.15));
				}
			}
		}
	}
}

.image-slider {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.slick-list,
.slick-track {
	height: 100%;
}

.image-slide {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	width: 100%;
	height: 100%;
	position: relative;
}

.topper-posts {
	padding: 120px 0 20px;
	@include breakpoint($bp-charlie) {
		padding: 160px 0 0 0;
	}
	@include breakpoint($bp-bobby) {
		padding: 200px 0 40px;
	}
	.col-md-8 {
		margin: 0 auto;
		float: none;
	}
	&.no-topper {
		.col-md-8 {
			display: table;
			margin: 0 auto 0 auto;
		}
		h6 {
			text-transform: uppercase;
			font-family: $primary-font;
			font-size: 16px;
			letter-spacing: 0.1em;
			color: $details;
		}
		h6, h1 {
			text-align: center;
		}
	}
	.page-meta {
		text-align: center;
		font-size: 13px;
		font-weight: 600;
		margin-bottom: 15px;
		span {
			padding: 0 10px;
			font-size: 20px;
			font-family: $headline-font;
			font-weight: 600;
			color: $body;
		}
		a {
			color: $body;
			&:hover {
				color: $secondary;
			}
		}
	}
	h1, h2 {
		margin-bottom: 0px
	}
	h2 {
		text-align: center;
		max-width: 700px;
	}
	.post-info {
		text-align: center;
		font-size: 18px;
		margin-top: 15px;
	}
	.sub-nav {
		@include breakpoint($bp-delta) {
			margin-bottom: 25px;
		}
	}
}

// Topper Call To Action Area

.topper-cta {
	float: left;
	width: 100%;
	.btn {
		margin: 0 auto;
		display: table;
	}
	.play-row {
    	width: 100%;
    	height: 100px;
    	margin-top: 40px;
    	.play-button {
    		position: relative;
    		margin: 0 auto;
    		display: table;
    		float: none;
    		left: auto;
    		top: 0;
    		cursor: pointer;
    		width: 60px;
    		height: 60px;
    		transition: .3s;
    		.play-bg {
    			background: #fff;
    			width: 25px;
    			height: 25px;
    			top: 18px;
    			left: 20px;
    			position: absolute;
    			transition: .3s;
    		}
    		svg {
    			z-index: 9;
    			position: relative;
    		}
    		@include breakpoint($bp-charlie) {
    			margin: 10px auto 0;
    			top: 0;
    			width: 90px;
    			height: 90px;
    			.play-bg {
    				width: 40px;
    				height: 40px;
    				top: 23px;
    				left: 28px;
    			}
    		}
    	}
	}
	.video-link {
		position: relative;
	}
	.video-popup {
		width: 75px;
		height: 75px;
		background: $primary;
		@include box-shadow($boxShadowTop, $boxShadowLeft, $boxShadowBlur, $boxShadowColor);
		border-radius: 100%;
		display: table;
		margin: 0 auto;
		position: relative;
		outline: none;
		@include hover-fade();
		&:hover {
			background: $secondary;
		}
		span {
			width: 30px;
			height: 35px;
			position: absolute;
			left: 0;
			right: 0;
			top: 22px;
			margin: 0 auto;
			svg {
				margin-left: 5px;
				width: 100%;
				height: auto;
			}
		}
	}
	.topper-form {
		margin: 0 auto;
		display: block;
		float: none;
		width: 100%;
		padding: 0;
		@include breakpoint($bp-adams) {
			width: 300px;
		}
		form {
			.email {
				float: left;
				width: 100%;
				margin-bottom: 4px;
			}
			.zip {
				float: left;
				width: 100%;
				margin-bottom: 13px;
			}
			.submit {
				width: 100%;
				float: left;
				margin-bottom: 0;
			}
		}
	}
}

// Topper Video Background

.topper-video {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
	z-index: -100;
	background-size: cover !important;
}

.fullscreen-bg__video {
	display: none;
	@include breakpoint($bp-charlie) {
  		display: block;
  		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

@media (min-aspect-ratio: 16/9) {
  .fullscreen-bg__video {
    height: 300%;
    top: -100%;
  }
}

@media (max-aspect-ratio: 16/9) {
  .fullscreen-bg__video {
    width: 300%;
    left: -100%;
  }
}

// Topper Menu


.blog .sub-nav, .archive .sub-nav, .category .sub-nav {
	background: #eee;
}

.sub-nav {
	float: left;
	width: 100%;
	padding: 0 20px;
	@include breakpoint($bp-bobby) {
		padding: 0 40px;
	}
	.container {
		padding: 0;
	}
	.social {
		margin: 0;
		float: right;
		display: none;
		height: 70px;
		background: #eeeeee;
		padding: 21px 30px;
		@include breakpoint($bp-bobby) {
			display: block;
		}
		span {
			margin: 0 10px 0;
			font-family: $headline-font;
			color: #666666;
			text-transform: none;
			font-weight: 600;
			font-size: 14px;
		}
		ul {
			li {
				a {
					padding: 0px 8px;
					color: #666666;
					&:hover {
						color: $secondary;
					}
				}
			}
		}
	}
	.topper-menu {
		float: left;
		width: 100%;
		display: block;
		overflow: hidden;
		@include breakpoint($bp-charlie) {
			display: table;
			margin: 0 auto;
			width: auto;
			float: none;
		}
		@include breakpoint($bp-bobby) {
			display: block;
			width: auto;
			float: none;
			margin: 0 auto;
			display: table;
		}
		&.news {
			display: table;
			margin: 0 auto;
			width: auto;
			float: none;
		}
	}
	nav {
		margin-top: 15px;
		margin-bottom: 15px;
		overflow: hidden;

		@include breakpoint($bp-charlie) {
			margin-top: 0;
			margin-bottom: 22px;
		}

		ul {
			margin: 0px;
			padding: 0px;

			li {
				text-align: center;
				float: left;
				width: 100%;

				@include breakpoint($bp-charlie) {
					width: auto;
					padding-top: 18px;
				}

				@include breakpoint($bp-bobby) {
					text-align: left;
				}

				&:first-of-type {
					a {
						&:after {
							display: none;
						}
					}
				}

				.sub-menu {
					display: none;
				}

				&.current-menu-item {
					a {
						&:before {
							background-color: $secondary;
							background-image: none;
							opacity: 1;
							@include transform(none);
						}

						&:hover:before {
							background-color: $primary;
						}
					}
				}

				a {
					font-size: 18px;
					font-weight: 700;
					font-family: $primary-font;
					display: block;
					text-align: center;
					color: $secondary;
					position: relative;
					margin: 5px 0;
					padding: 5px 10px;
					@include underline();

					@include breakpoint($bp-charlie) {
						padding: 5px 25px;
					}

					&:before {
						opacity: 0;
						height: 4px;
						left: 5px;
						right: 5px;
						@include hover-fade();
						@include transform(scaleX(0));

						@include breakpoint($bp-charlie) {
							margin-left: 15px;
							margin-right: 15px;
						}
					}

					&:after {
						@include breakpoint($bp-charlie) {
							content: '';
							background-color: $details;
							width: 1px;
							height: 75%;
							position: absolute;
							left: 0;
							top: 50%;
							@include transform(translateY(-50%));
						}
					}

					&:hover {
						color: $primary;

						&:before {
							opacity: 1;
							@include transform(none);
						}
					}
				}
			}
		}
	}
}
