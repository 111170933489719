.social {
	&.links {
		float: left;
		font-size: 20px;
		margin-top: 8px;
		span {
			float: left;
			display: block;
			text-align: center;
			font-size: 12px;
			text-transform: uppercase;
			margin: 13px 15px 0 15px;
		}
		ul {
			margin: 0px;
			padding: 0px;
			float: right;
			li {
				list-style-type: none;
				float: left;
				a {
					padding: 6px 15px;
					float: left;
					color: $body;
					&:hover {
						color: $secondary;
					}
				}
			}
		}
	}
}

.social-nav {
    position: fixed;
    bottom: 0;
    z-index: 99;
    width: 100%;
    opacity: 1;
    @include transform(translateY(-50%) translateX(0px));
    @include hover-fade();
    display: none;
    &.animate {
        opacity: 0;
        @include transform(translateY(-50%) translateX(50px));
    }
    &.behind {
        z-index: -99;
    }
    @include breakpoint($bp-bobby) {
        display: block;
        width: 62px;
        position: fixed;
        right: 0!important;
        top: 50%!important;
        left: auto!important;
        bottom: auto;
        @include transform(translateY(-50%) translateX(-50px));
    }
    .social-side {
        display: none;
        @include breakpoint($bp-charlie) {
            display: block;
        }
        p {
            font-family: $primary-font;
            font-size: 14px;
            letter-spacing: 0.1em;
            color: $primary;
            font-weight: 700;
            text-align: center;
            text-transform: uppercase;
            margin-bottom: 10px;
            width: 100%;
        }
        .share-slide {
            background: #fff;
            border: 1px solid $details;
						overflow: hidden;
            @include hover-fade();
            .social.links {
							float: none;
                margin-top: 0;
                ul {
									float: none;
									width: 100%;
                    li {
											float: none;
											width: 100%;
                        a {
														text-align: center;
														float: none;
														display: block;
														width: 100%;
														height: 58px;
														line-height: 58px;
														padding-top: 0;
														padding-bottom: 0;
                            color: $primary;
                            &:hover {
															background-color: $primary;
															color: #fff;
                            }
                        }
                    }
										li + li {
											border-top: 1px solid $details;
										}
                }
            }
        }
        .box {
            background: transparent;
            border: 1px solid $socialColor;
            border-right: none;
            text-align: center;
            padding: 14px 0;
            cursor: pointer;
            color: $socialColor;
            margin-bottom: 10px;
            z-index: 6;
            position: relative;
            @include hover-fade();
            i {
                font-size: 22px;
                font-weight: 400;
                color: $socialColor;
                @include hover-fade();
            }
            &:hover {
                border: 1px solid $socialHover;
                background: $socialHover;
                i {
                    color: #fff;
                }
            }
        }
        & {
            .box {
                border: 1px solid $socialHover;
                background: $socialHover;
                i {
                    color: #fff;
                }
            }
        }
    }
}
