.topper.home h1.super em:before {
	@include breakpoint($bp-bobby) {
		bottom: -3px;
	}
}

.home-about {
	text-align: center;
	float: left;
	width: 100%;
	background: #fff;
	padding: 50px 0;
	@include breakpoint($bp-charlie) {
		padding: 85px 0;
	}
	@include breakpoint($bp-bobby) {
		text-align: left;
		padding: 125px 0;
	}
}

.about-headlines {
	position: relative;

	&:before {
		content: '';
		background-color: $primary;
		background-image: linear-gradient(to right, $primary, $tertiary);
		position: absolute;
		left: 30%;
		right: 30%;
		bottom: 0;
		height: 10px;
	}

	@include breakpoint($bp-bobby) {
		padding-left: 80px;

		&:before {
			background-image: linear-gradient(to bottom, $primary, $tertiary);
			top: 5px;
			left: 0;
			right: auto;
			bottom: 35px;
			width: 15px;
			height: auto;
		}
	}
}

.about-above-headline {
	font-size: 30px;
	margin-bottom: 0;

	@include breakpoint($bp-bravo) {
		font-size: 42px;
	}
}

.about-headline {
	font-size: 80px;
	font-weight: 400;

	@include breakpoint($bp-bravo) {
		font-size: 120px;
	}
}

.about-cta {
	margin-top: 3rem;
}

.home-pa {
	background-color: #576778;
	text-align: center;
	float: left;
	width: 100%;
	padding: 50px 20px;
	position: relative;

	@include breakpoint($bp-charlie) {
		padding: 85px 40px;
	}

	@include breakpoint($bp-bobby) {
		padding: 125px 40px;
	}

	.container {
		padding-left: 0;
		padding-right: 0;
	}
}

.pa-background {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	opacity: 0.15;
	position: absolute;
	z-index: 0;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.pa-headline {
	color: #fff;
}

.pa-description {
	p {
		color: #fff;
	}
}

.pa-practice-area {
	.home-pa .issues-grid & {
		height: 280px;
		border-color: #fff;

		.wrapper h5 {
			color: #fff;
		}

		&:hover {
			@include transform(scale(1.05, 1.05));
		}
	}
}

.home-quotes {
	&.module.block-quote .container {
		border-top: 0;
	}
}
