::-moz-selection { background: $secondary; color:#fff; }
::selection { background: $secondary; color:#fff; }

html {
	overflow-x: hidden;
}

body, html {
	background-color: $body!important;
  height: 100%;
    color: $body;
    //@include transition(all 0.3s ease-in-out);
	-webkit-font-smoothing: antialiased;
    main {
        background: #fff;
    }
    &.search-open {
       // margin-top: 61px;
        header {
        }
        .topper, .topper-home {
            //top: 61px;
        }
        .top-search {
            top: 0;
            input[type="text"] {
                opacity: 1;
            }
        }
    }
}

a {
	color: $primary;
	@include hover-fade();
	&:hover {
		color: $secondary;
	}
}

p a {
	text-decoration: underline;
	font-weight: 700;
}

.arrow-link {
	text-transform: uppercase;
	font-family: $primary-font;
	font-weight: 700;
	font-size: 15px;
	letter-spacing: 0.1em;

	@include breakpoint($bp-charlie) {
		font-size: 17px;
	}

	svg {
		width: 20px;
		height: 13px;
		vertical-align: middle;
		margin-left: 18px;
		@include transform(translateY(-2px));
	}

	.cls-1 {
		stroke: $primary;
		@include hover-fade();
	}

	&:hover {
		.cls-1 {
			stroke: $secondary;
		}
	}
}

.unstyled-link{
    color: $body !important;
}

.overlay {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background: linear-gradient(to bottom, rgba($secondary, 1) 0%, rgba($secondary, 0.5) 40%, rgba($secondary, 0.5) 45%, rgba($secondary, 1) 100%);
    z-index: 1;
}



.pagination {
    list-style: none;
    margin: 0 auto;
    text-align: center;
    float: left;
    width: 100%;
    position: relative;

    ul {
        margin: 0!important;
        padding: 45px 15px;
        @include breakpoint($bp-charlie) {
            padding: 80px 15px;
        }
    }
    li {
        margin: 0px !important;
        display: inline-block;
        float: none;
        text-align: center;
        font-family: $headline-font;
        font-size: 17px;
        font-weight: 600;
        color: #999999;
        position: relative;
        padding:0 12px 0 10px;
        &:last-of-type {
            &:after {
                display: none!important;
            }
        }
        &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 1px;
            background: #d1d2d5;
        }
    }
    a, span {
        line-height: 1.6em;
        margin: 0px 10px;
        padding: 5px 2px;
        display: inline-block;
        font-size: 18px;
        text-decoration: none;
        position: relative;
        color: #3b3d4d;
        svg {
            margin-bottom: 3px;
            width: 14px;
            height: 18px;
            fill: #3b3d4d;
            @include transition(all 0.2s ease-in-out);
            &:hover {
                fill: $secondary;
            }
        }
    }
    .prev {
        @include transform(rotate(-180deg));
    }
    span.current {
        color: #b7bac2;
    }
    a {
        &:hover {
            color: $secondary;
        }
    }
}


svg title {
  pointer-events: none;
}
