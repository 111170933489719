.list-page {
	.top-introduction {
		padding-bottom: 0;
		margin-bottom: 0;
	}
	.list-grid {
		&.search {
			padding: 65px 0 15px 0;
			@include breakpoint($bp-charlie) {
				padding: 85px 0 50px 0;
			}
		}
		.col-md-9 {
			float: none;
			display: table;
			margin: 0 auto;
		}
		.main-headline {
			margin-top: 40px;
			margin-bottom: 30px;
			@include breakpoint($bp-charlie) {
				margin-bottom: 45px;
				margin-top: 50px;
			}
			h3 {
				text-align: center;
				margin: 0;
			}
		}
		.single-row {
			margin-bottom: 35px;
			padding-bottom: 35px;
			border-bottom: 1px solid #cccccc;
			@include breakpoint($bp-charlie) {
				margin-bottom: 80px;
				padding-bottom: 80px;
			}
			.description {
				p {
					&:last-of-type {
						margin-bottom: 0;
					}
				}
			}
			.links {
				margin-top: 15px;
				overflow: hidden;
				@include breakpoint($bp-charlie) {
					margin-top: 25px;
				}
				a {
					display: inline-block;
					font-family: $primary-font;
					font-weight: 700;
					font-size: 16px;
					margin-right: 35px;
					position: relative;
					padding-right: 15px;

					&:hover {
						color: #80868c;

						.cls-1 {
							stroke: #80868c;
						}
					}

					svg {
						@include transform(translateY(-1px));
					}
				}
			}
		}
	}
}
