// Alert Bart
.alert-bar {
  width: 100%;
  background: $tertiary;
  position: relative;
  z-index: 9;
  transition: all 0.6s;

  .alert-bar--inner {
    padding: 9px 25px;
  }

  p {
    padding: 0px 25px;
    margin: 0px;
    text-align: center;
    color: #fff;
    font-family: $primary-font;
    font-weight: 600;
    line-height: 116%;
    font-size: 13px;

    @include breakpoint($bp-charlie) {
      padding: 0 15px;
      line-height: 125%;
      font-size: 15px;
    }

    span {
      text-transform: uppercase;
      margin-right: 10px;
      font-size: 15px;
    }
  }

  .alert-link {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .close-icon {
    cursor: pointer;
    position: absolute;
    top: 50%;
    height: 100%;
    @include transform(translateY(-50%));
    right: 15px;
    width: 21px;
    height: 21px;
    min-height: 0;
    border-radius: 100%;
    background: rgba(255,255,255,0.25);
    @include hover-fade();

    svg {
      position: absolute;
      top: 5px;
      left: 5px;
      width: 11px;
      height: 11px;
      fill: #fff;
    }
    &:hover {
      background: rgba(#fff,1);
      svg {
        fill : $secondary;
      }
    }
  }

  &.has-link {
    &:hover {
      background: darken($tertiary, 10%);
    }
    .close-icon .fa:hover {
      color: $body;
    }
	}
}
