$buttonBorderRadius: max($borderRadius - 1px, 0px);//not sure why button is 1px less than modules but it is atm

.btn {
	background: $primary;
	border-radius: $buttonBorderRadius;
	color: #fff;
	cursor: pointer;
	display: block;//should be inline-block, for the base case...
	text-transform: uppercase;
	font-size: 16px;
	font-weight: 700;
	font-family: $primary-font;
	letter-spacing: 0.1em;
	line-height: 120%;
	max-width: 350px;
	padding: 17px 15px;
	text-align: center;
	width: auto;
	position: relative;
	z-index: 1;
	@include hover-fade();

	@include breakpoint($bp-alpha) {
		min-width: 265px;
	}

	@include breakpoint($bp-alpha, max) {
		width: 100%;
	}

	&:before {
		content: '';
		background-image: linear-gradient(to bottom, $primary, $tertiary);
		display: block;
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 1;
		@include hover-fade();
	}

	&:hover {
		background: $secondary;
		color: #fff;

		&:before {
			opacity: 0;
		}
	}

	&.alt {
		background: $secondary;

		&:hover {
			background: $primary;
		}
	}
}
