.bottom-cta {
	float: left;
	width: 100%;
	background-color: $primary;
	background-size: cover !important;
	position: relative;
	padding: 40px 0;
	color: white;
	@include breakpoint($bp-charlie) {
		height: 750px;
	}
	h1, h6 {
		color: #fff;
		text-align: center;
	}
	h6 {
		margin-bottom: 5px;
	}
	h1 {
		margin-bottom: 25px;
	}
	.deck {
		margin-bottom: 35px;
		color: #fff;
		position: relative;
		p {
			color: #fff;
		}
	}
	.container, .row {
		height: 100%;
	}
	.play-row {
    	width: 100%;
    	height: 100px;
    	margin-top: 40px;
    	.play-button {
    		position: relative;
    		margin: 0 auto;
    		display: table;
    		float: none;
    		left: auto;
    		top: 0;
    		cursor: pointer;
    		width: 60px;
    		height: 60px;
    		transition: .3s;
    		.play-bg {
    			background: #fff;
    			width: 25px;
    			height: 25px;
    			top: 18px;
    			left: 20px;
    			position: absolute;
    			transition: .3s;
    		}
    		svg {
    			z-index: 9;
    			position: relative;
    		}
    		@include breakpoint($bp-charlie) {
    			margin: 10px auto 0;
    			top: 0;
    			width: 90px;
    			height: 90px;
    			.play-bg {
    				width: 40px;
    				height: 40px;
    				top: 23px;
    				left: 28px;
    			}
    		}
    	}
	}
	.video-link {
		position: relative;
	}
	.video-popup {
		width: 75px;
		height: 75px;
		background: $primary;
		@include box-shadow($boxShadowTop, $boxShadowLeft, $boxShadowBlur, $boxShadowColor);
		border-radius: 100%;
		display: table;
		margin: 0 auto;
		position: relative;
		outline: none;
		@include hover-fade();
		&:hover {
			background: $secondary;
		}
		span {
			width: 30px;
			height: 35px;
			position: absolute;
			left: 0;
			right: 0;
			top: 22px;
			margin: 0 auto;
			svg {
				margin-left: 5px;
				width: 100%;
				height: auto;
			}
		}
	}
	.bottom-cta-form {
		margin: 0 auto;
		display: table;
		float: none;
		max-width: 560px;
		padding: 0;
		.form-wrapper {
			max-width: 300px;
			width: 100%;
			margin: 0 auto;
			display: table;
		}
		.btn {
			display: table;
			float: none;
			max-width: 380px;
			margin: 0 auto;
		}
		form {
			.email {
				float: left;
				width: 100%;
				margin-bottom: 4px;
			}
			.zip {
				float: left;
				width: 100%;
				margin-bottom: 13px;
			}
			.submit {
				width: 100%;
				float: left;
				margin-bottom: 0;
			}
		}
	}
}

footer {
	float: left;
	width: 100%;
	padding: 70px 0;
	color: #858891;
	background: $bunting;

	@include breakpoint($bp-charlie) {
		padding: 90px 0;
	}

	&.submenu {
		padding: 40px 0 100px;

		@include breakpoint($bp-charlie) {
			padding: 40px 0;
		}
	}

	svg {
		width: 200px;
		height: 40px;

		.st0, .st1 {
			fill: #fff;
		}
	}

	a {
		color: #fff;

		&:hover {
			color: rgba(#fff, 0.5);
		}
	}

	.row {
		&:first-of-type {
			padding-bottom: 10px;
			border-bottom: 1px solid #545865;

			@include breakpoint($bp-charlie) {
				padding-bottom: 80px;
			}
		}

		&:nth-child(2) {
			padding-top: 50px;

			@include breakpoint($bp-charlie) {
				padding-top: 70px;
			}
		}
	}

	.logo-col {
		text-align: center;
		margin-bottom: 50px;

		@include breakpoint($bp-charlie) {
			text-align: left;
		}
	}

	nav {
		&.footer-secondary {
			display: block;
			vertical-align: middle;
			margin-top: 10px;

			@include breakpoint($bp-charlie) {
				display: inline-block;
				margin-top: 0;
			}

			ul {
				margin: 0 auto;
				float: none;

				li {
					text-align: center;
					display: inline-block;

					@include breakpoint($bp-alpha) {
						margin-left: 20px;

						&:first-of-type {
							margin-left: 0;
						}
					}

					@include breakpoint($bp-charlie) {
						margin-left: 30px;

						&:first-of-type {
							margin-left: 20px;
						}
					}

					a {
						text-decoration: underline;
						font-size: 16px;
					}
				}
			}
		}

		&.primary {
			float: left;
			width: 100%;
			display: block;
			margin-bottom: 20px;

			ul {
				text-align: center;
				margin: 0 auto;
				float: none;

				li {
					text-align: center;
					font-family: $headline-font;
					font-weight: 400;
					display: inline-block;
					padding: 13px;

					&.home {
						display: none;
					}

					a {
						font-size: 16px;
					}
				}
			}
		}
	}

	.social {
		display: none;

		@include breakpoint($bp-bobby) {
			display: block;
		}

		&.links {
			float: left;
			width: 100%;
			margin-top: 0;

			ul {
				margin: 0 auto;
				display: table;
				float: right;

				li {
					a {
						color: #fff;
						padding-top: 0;
						padding-right: 0;
						padding-left: 30px;

						&:hover {
							color: rgba(#fff, 0.5);
						}

						i {
							font-size: 24px;

							@include breakpoint($bp-charlie) {
								font-size: 26px;
							}
						}
					}
				}
			}
		}
	}
}

.footer-locations {
	text-align: center;
	font-size: 0;
}

.footer-location {
	text-align: center;
	font-size: 1rem;
	width: 100%;
	display: inline-block;
	vertical-align: top;
	margin-bottom: 44px;

	@include breakpoint($bp-charlie) {
		text-align: left;
		width: 37%;
		margin-bottom: 0;
		padding-right: 10px;

		&:last-of-type {
			width: 26%;
		}
	}

	h6 {
		font-weight: 700;
		color: #fff;
	}

	address,
	span {
		font-style: normal;
		font-size: 16px;
		line-height: 1.4;
		display: block;
	}
}

.footer-meta {
	text-align: center;
	float: left;
	width: 100%;

	.post-copyrights {
		@include breakpoint($bp-charlie) {
			margin-left: 15px;
		}
	}

	span {
		display: inline-block;
		vertical-align: middle;
		font-size: 16px;

		&.campaign-name {
			margin-top: 15px;
		}

		&.paid-for {
			text-align: center;
			border: 1px solid rgba($footer, .25);
			padding: 7px 20px;
			width: 100%;
			color: rgba($footer, .5);
			margin: 0 auto;
			max-width: 620px;
			float: none;
			display: table;
			font-size: 12px;
		}

		&.site-by {
			margin-top: 10px;
			text-align: center;
			font-weight: 400;
			color: #999999;
			margin-top: 15px;

			a {
				color: #999999;

				&:hover {
					color: $secondary;
				}
			}
		}
	}
}
